enum TokenType {
  Text,
  Expression,
  EachBlock,
}

interface TemplateToken {
  type: TokenType;
  value?: string;
  escaped?: boolean;
  arrayName?: string;
  blockContent?: string;
}

type Variables = { [key: string]: number | boolean | string | Variables };

export class TemplateCompiler {
  private template: string;

  constructor(template: string) {
    this.template = template;
  }

  compile(variables: any) {
    const tokens = this.parse(this.template);
    return this.render(tokens, variables);
  }

  private parse(template: string) {
    const regex = /\s*{{#each\s+(\w+)\s*}}([\s\S]*?)\s*{{\/each}}|{{{\s*(.*?)\s*}}}|{{\s*(.*?)\s*}}/g;
    let match: RegExpExecArray;
    const tokens: TemplateToken[] = [];
    let lastIndex = 0;

    while ((match = regex.exec(template)) !== null) {
      if (match.index > lastIndex) {
        tokens.push({ type: TokenType.Text, value: template.slice(lastIndex, match.index) });
      }

      if (match[1] && match[2]) {
        tokens.push({ type: TokenType.EachBlock, arrayName: match[1], blockContent: match[2] });
      } else if (match[3] || match[4]) {
        const expression = match[3] || match[4];
        tokens.push({ type: TokenType.Expression, value: expression.trim(), escaped: !!match[4] });
      }

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < template.length) {
      tokens.push({ type: TokenType.Text, value: template.slice(lastIndex) });
    }

    return tokens;
  }

  private render(templateTokens: TemplateToken[], variables: { [key: string]: string }) {
    let output = "";
    for (const templateToken of templateTokens) {
      if (templateToken.type === TokenType.Text) {
        output += templateToken.value;
      } else if (templateToken.type === TokenType.Expression) {
        output += this.renderExpression(templateToken, variables);
      } else if (templateToken.type === TokenType.EachBlock) {
        output += this.renderEachBlock(templateToken, variables);
      }
    }
    return output;
  }

  private renderExpression(templateToken: TemplateToken, variables: { [key: string]: string }) {
    const value = this.getVariable(variables, templateToken.value);
    if (!value) return "";
    return templateToken.escaped ? this.escapeHtml(String(value)) : value;
  }

  private renderEachBlock(templateToken: TemplateToken, variables: { [key: string]: string }) {
    const array = this.getVariable(variables, templateToken.arrayName);
    if (!array) return;
    let output = "";
    if (Array.isArray(array)) {
      array.forEach((item) => {
        const compiler = new TemplateCompiler(templateToken.blockContent);
        output += compiler.compile(item);
      });
    }
    return output;
  }

  private getVariable(variables: Variables, variableName: string) {
    const keys = variableName?.split(".");
    let value: string | Variables = variables;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) break;
    }
    return value;
  }

  private escapeHtml(unsafe: string): string {
    return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }
}

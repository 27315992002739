export const debugMessage = DEBUG_ENABLED ? console.log.bind(console) : () => {};

// Matches things like index.html or default.asp
const INDEX_REGEX = /^($|index\.\w{2,4}|default\.\w{2,4})/i;

export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function isMobile() {
  return window.matchMedia("only screen and (max-width: 480px)").matches;
}

export function shouldInstallWidgetOnDevice(disableOnMobile: boolean, disableOnDesktop: boolean) {
  const is_mobile = isMobile();
  if ((is_mobile && !disableOnMobile) || (!is_mobile && !disableOnDesktop)) {
    return true;
  }
  return false;
}

export function getPath() {
  const parser = document.createElement("a");
  parser.href = window.location.href;
  return parser.pathname.replace(/^\/*/, "");
}

function findMatchingLocation(path: string) {
  let location: string;
  if (PLUGIN_CONFIG.apply_to_path_and_query) {
    path = path + window.location.search;
  }
  for (const key in PLUGIN_LOCATION_CONFIGS) {
    const location_config = PLUGIN_LOCATION_CONFIGS[key];

    if (typeof location_config.url_regex !== "undefined" && path.match(new RegExp(location_config.url_regex, "i"))) {
      location = key;
      break;
    }
    if (typeof location_config.custom_url_regex !== "undefined" && path.match(new RegExp(location_config.custom_url_regex, "i"))) {
      location = key;
      break;
    }
  }
  return location;
}

export function getPluginLocation() {
  const path = getPath();
  let location: string;
  // if we should use the query string, first check for a match
  if (PLUGIN_CONFIG.apply_to_path_and_query) {
    location = findMatchingLocation(path);
  }
  // if that didn't find anything, then look to see if its the root page
  if (!location) {
    const noQuery = !PLUGIN_CONFIG.apply_to_path_and_query;
    const emptyPath = path === "" && (noQuery || window.location.search === "");
    const defaultMatch = path !== "" && path.match(INDEX_REGEX);
    if (typeof PLUGIN_LOCATION_CONFIGS["home"] !== "undefined" && (emptyPath || defaultMatch)) {
      location = "home";
    } else {
      location = findMatchingLocation(path);
    }
  }
  return location;
}

export async function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

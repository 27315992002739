const USE_LOCALHOST = location.href.match(/[?&]snapcell-use-localhost=/);
const HOST = USE_LOCALHOST ? "http://localhost:3002" : "https://dashboard.snapcell.us.com";
const WIDGET_URL = HOST + "/api/franchises/vehicle_video";
const TOKEN = "eslr84hN8sh3erNshui48sn_nsnNNserofe78Nfern_firh98nfhEisheiOhreN3nrUnfihreN_snfEnf8V23n";

const buildVideo = () => {
  const SNAPCELL_WIDGET = `
<video id="snapcell-video" width="100%" controls preload="metadata" poster="" >
  <source id="snapcell-video-source" src="">
</video>
`;
  const videoDiv = document.createElement("div");
  videoDiv.innerHTML = SNAPCELL_WIDGET;

  return videoDiv;
};

const appendWidget = (root: Element) => {
  const snapcellVideo = buildVideo();
  root.appendChild(snapcellVideo);
};

const applyVideoSource = (videoUrl: string, thumbUrl: string, preloadDisabled: boolean) => {
  const snapcellVideoSource = document.getElementById("snapcell-video-source") as HTMLVideoElement;
  snapcellVideoSource.src = videoUrl;

  const snapcellVideo = document.getElementById("snapcell-video") as HTMLVideoElement;
  snapcellVideo.poster = thumbUrl || "";

  if (preloadDisabled) {
    snapcellVideo.preload = "none";
  }
};

const fetchVideo = async (franchiseId: string, vin: string): Promise<{ videoUrl: string; videoThumb: string }> => {
  const params = `?franchise_id=${franchiseId}&vin=${vin}`;
  const url = WIDGET_URL + params;
  const headers = new Headers({
    Authorization: `Bearer ${TOKEN}`,
  });

  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    throw new Error("Failed to get SnapCell widget video url.");
  }

  const data = await response.json();
  // This is how the response looks like:
  // {
  //   video_url: https://s3.videourl.com/video.mp4,
  //   thumb_url: https://s3.videorurl.com/thumbnail.png,
  // }

  if (data.video_url !== null) {
    return { videoUrl: data.video_url, videoThumb: data.thumb_url };
  } else {
    throw new Error("No video URL found");
  }
};

const fetchVideosForVins = async (franchiseId: string, vins: string[]): Promise<{ [key: string]: boolean }> => {
  const url = `${HOST}/api/${franchiseId}/video_for_vehicles?vins=${vins.join(",")}`;
  const response = await fetch(url);
  return response.json();
};

export const installSrpCtasWithDDC = async (franchiseId: string, DDC_API?: any) => {
  const snapcellModal = `
      <div id="snapcell-modal-content" class="mx-0 mx-lg-5 mx-xl-auto" style="max-width: 1200px; background-color: #fefefe;">
        <div class="media-modal-header d-flex align-items-center justify-content-center text-center" style="height: 4em;">
          <span id="snapcell-vehicle-title" class="h3 font-weight-bold" style="width: 100%;"></span>
          <button id="snapcell-modal-close" type="button" class="media-modal-button text-decoration-none pull-right btn btn-link" title="Close">
            <i class="ddc-icon ddc-icon-cancel-circle ddc-icon-size-xlarge"></i>
          </button>
        </div>
        <div class="embed-responsive embed-responsive-16by9" style="max-height:calc(100vh - 330px);">
          <iframe id="snapcell-modal-iframe" class="embed-responsive-item" src="" style="width: 100%; max-height: 720px;" scrolling="no"></iframe>
        </div>
        <div class="p-4 d-flex justify-content-between align-items-center">
          <a class="pull-left" target="_blank" href="https://snapcell.us.com/">
            <img style="border: solid #df5e0f 1px;border-radius: 9px;width: 85px;padding: 4px 5px 2px 5px;background: white;" src="https://assets.snapcell.us.com/scimages/poweredbysnapcell-xs.jpg">
          </a>
          <a id="snapcell-vehicle-link" class="btn btn-default" href="">More Details</a>
        </div >
      </div>
    `;
  const modal = document.createElement("div");
  modal.id = "snapcell-modal";
  modal.innerHTML = snapcellModal;
  modal.setAttribute("style", "display: none;position: fixed; top: 0; left: 0;width: 100%;height: 100%;padding-top: 130px;z-index: 1100;background-color: rgba(0,0,0,0.4);");

  const showModal = (vehicle) => {
    const { year, make, model, trim, vin, link } = vehicle;
    const title = [year, make, model, trim].join(" ");

    document.getElementById("snapcell-vehicle-title").innerText = title;
    document.getElementById("snapcell-vehicle-link").setAttribute("href", link);
    (document.getElementById("snapcell-modal-iframe") as HTMLIFrameElement).src = `https://dashboard.snapcell.us.com/franchise/${franchiseId}/video_iframe_by_vin/${vin}`;
    document.getElementById("snapcell-modal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("snapcell-modal").style.display = "none";
    (document.getElementById("snapcell-modal-iframe") as HTMLIFrameElement).src = "";
  };

  document.body.appendChild(modal);

  const closeBtn = document.getElementById("snapcell-modal-close");
  closeBtn.addEventListener("click", (ev) => {
    ev.preventDefault();
    closeModal();
  });

  modal.addEventListener("click", (ev) => {
    if (ev.target === modal) {
      ev.preventDefault();
      closeModal();
    }
  });

  DDC_API.subscribe("vehicle-data-updated-v1", async (ev) => {
    const vins = await DDC_API.utils.getAttributeForVehicles("vin");
    const videoVins = await fetchVideosForVins(franchiseId, vins);
    DDC_API.insertOnce("vehicle-media", (elem, meta) => {
      if (videoVins.vins[meta.vin]) {
        const button = DDC_API.create("button", {
          text: "Watch Video",
          classes: "btn btn-primary",
          style: "margin-top: 12px;",
          onclick: () => {
            showModal(meta);
          },
        });
        DDC_API.append(elem, button);
      }
    });
  });
};

export const installVideoWithDDC = async (franchiseId: string, DDC_API?: any) => {
  const pageData = await DDC_API.utils.getPageData();
  if (!pageData.detailPage) {
    // Not VDP
    return;
  }

  DDC_API.insert("vehicle-media", async (elem, meta) => {
    const resp = await fetchVideo(franchiseId, meta.vin);
    const div = document.createElement("div");
    div.id = "snapcell-video-div";
    DDC_API.append(elem, div);
    appendWidget(div);
    applyVideoSource(resp.videoUrl, resp.videoThumb, true);
  });
};

export const installVideo = async (franchiseId: string, vin: string, videoDivSelector: string, preloadDisabled: boolean) => {
  try {
    const resp = await fetchVideo(franchiseId, vin);
    const selector = videoDivSelector || "#snapcell-video-div";
    const root = document.querySelector(selector);
    if (!root) {
      console.warn("Snapcell video selector invalid: " + selector);
      return;
    }
    appendWidget(root);
    applyVideoSource(resp.videoUrl, resp.videoThumb, preloadDisabled);
  } catch (err) {
    console.error(err.message);
  }
};

import { DdcApi } from "./models";
import { getPluginLocation, sleep } from "./pluginUtil";

const DDC_LANDING_PAGE_LOCATION = "/tools/tradepending/snap-trade-in.htm";
const DDC_LANDING_PAGE_LOCATIONS = {
  snap: { pageName: "TRADEPENDING_TRADE", location: "/tools/tradepending/value-your-trade.htm" },
  snapOffer: { pageName: "TRADEPENDING_OFFER", location: "/tools/tradepending/sell-my-car.htm" },
  reveal: { pageName: "TRADEPENDING_PAYMENTS", location: "/tools/tradepending/whats-my-buying-power.htm" },
  approve: { pageName: "TRADEPENDING_APPROVE", location: "/tools/tradepending/get-prequalified.htm" },
};

function isDdcLandingPage() {
  for (const locationConfig of Object.values(DDC_LANDING_PAGE_LOCATIONS)) {
    if (window.location.pathname.includes(locationConfig.location)) {
      return true;
    }
  }
  return this.isOldDdcLandingPage();
}

function isOldDdcLandingPage() {
  return window.location.pathname.includes(DDC_LANDING_PAGE_LOCATION);
}

function isDdcPageNameMatch(pageName: string, matchedNames: string[]) {
  const names = matchedNames.map((name) => DDC_LANDING_PAGE_LOCATIONS[name]?.pageName).filter((n) => n);
  return names.some((matchedName) => pageName.includes(matchedName));
}

async function waitForDDCAPI() {
  const pluginLocation = getPluginLocation();
  if (!pluginLocation) {
    return;
  }
  const use_ddc_api = PLUGIN_CONFIG?.[pluginLocation]?.use_ddc_api ?? SUPERLATIVE_CONFIG?.[pluginLocation]?.use_ddc_api ?? AUTOAPR_CONFIG?.[pluginLocation]?.use_ddc_api;

  let interval = 0;
  const sleepTime = 1000;

  while (use_ddc_api && !window.DDC?.API && interval <= 5) {
    await sleep(sleepTime * ++interval);
  }
  if (window.DDC?.API) {
    return new window.DDC.API("tradepending") as DdcApi;
  }
}

const ddcUtils = {
  waitForDDCAPI,
  isOldDdcLandingPage,
  isDdcPageNameMatch,
  isDdcLandingPage,
};

export default ddcUtils;

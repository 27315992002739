declare global {
  interface Window {
    tp_datalayer: any;
    tp_gtag: any;
  }
}

type EventName = string;
type EventParams = { [key: string]: any };
const eventContext = new Map<string, EventParams>();
const GA_ID = "G-GH9FXE8RZM";
let initPromise: Promise<void>;

export async function initAnalytics(pluginLocation: string) {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}&l=tp_datalayer`;

  initPromise = new Promise<void>((resolve, reject) => {
    script.onload = () => {
      window.tp_datalayer = window.tp_datalayer || [];
      // tslint:disable-next-line:only-arrow-functions
      window.tp_gtag = function () {
        window.tp_datalayer.push(arguments);
      };
      window.tp_gtag("js", new Date());

      window.tp_gtag("config", GA_ID, { location: pluginLocation, dealer_id: DEALER_ID });
      window.tp_gtag("set", "user_properties", {
        dealer_id: DEALER_ID,
      });
      resolve();
    };
  });
  document.head.appendChild(script);

  return initPromise;
}
/** This will only log the event to our internal firebase/GA  */
export async function logEvent(event: EventName, params?: EventParams) {
  await initPromise;
  const context = eventContext.get(event);
  let eventParams = { did: DEALER_ID, ...params, send_to: GA_ID };
  if (eventContext.has(event)) {
    eventParams = { ...eventParams, ...context };
  }
  window.tp_gtag("event", event, eventParams);
}

// Add some context for a particular analytics event.
// Whenever that event is logged, the extra context will be merged into the parameters
export function addEventContext(event: EventName, key: string, value: any) {
  const params = {};
  params[key] = value;
  if (!eventContext.has(event)) {
    eventContext.set(event, params);
  } else {
    eventContext.set(event, { ...eventContext.get(event), ...params });
  }
}

type EventName = "cleanup" | "closed";

const preventScroll = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

export class IFrameModal {
  private dialog: HTMLDialogElement;
  private url: string;
  private options: { width: string; height: string; top?: string; fixed?: boolean };
  private eventListeners: { [event: string]: (() => void)[] } = {};

  constructor(
    url: string,
    options: {
      height: string;
      width: string;
      top?: string;
      fixed?: boolean;
    }
  ) {
    this.url = url;
    this.options = options;
  }

  open() {
    document.addEventListener("wheel", preventScroll, { passive: false });
    document.addEventListener("touchmove", preventScroll, { passive: false });
    this.dialog = document.createElement("dialog");
    this.dialog.setAttribute("id", "tp-modal");
    this.dialog.onclose = (evt) => {
      evt.preventDefault();
      this.close();
    };

    const createCloseButton = () => {
      const closeButton = document.createElement("button");
      closeButton.setAttribute("type", "button");
      closeButton.id = "tp-close";
      closeButton.innerHTML = "&times;";
      closeButton.setAttribute("aria-label", "Close modal");

      closeButton.onclick = () => {
        this.close();
      };
      return closeButton;
    };

    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", this.url);
    iframe.setAttribute("frameborder", "0");
    this.dialog.style.height = this.options.height;
    this.dialog.style.width = this.options.width;
    if (this.options.fixed) {
      this.dialog.style.position = "fixed";
    }
    if (this.options.top) {
      this.dialog.style.top = this.options.top;
    }
    iframe.allowFullscreen = true;
    iframe.style.display = "none";
    this.dialog.appendChild(iframe);

    const loadingOverlay = document.createElement("div");
    loadingOverlay.id = "loading-overlay";
    const loadingGraphic = document.createElement("div");
    loadingGraphic.id = "loading-graphic";

    document.body.appendChild(this.dialog);
    this.dialog.showModal();
    this.dialog.classList.add("tp-fade-in");

    this.dialog.appendChild(loadingOverlay);
    this.dialog.appendChild(loadingGraphic);
    this.dialog.appendChild(createCloseButton());

    iframe.onload = () => {
      loadingGraphic.style.display = "none";
      loadingOverlay.style.display = "none";
      iframe.style.removeProperty("display");

      this.dialog.focus();
    };

    // Detect when we've clicked outside of the dialog
    this.dialog.addEventListener("click", (event) => {
      if (!this.dialog) {
        return;
      }
      const rect = this.dialog.getBoundingClientRect();
      const isInDialog = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
      if (!isInDialog) {
        event.preventDefault();
        this.close();
      }
    });
  }

  close() {
    if (!this.dialog) {
      return;
    }
    const dialog = this.dialog;
    this.dialog = null;
    this.emit("cleanup");
    dialog.classList.remove("tp-fade-in");
    dialog.classList.add("tp-fade-out", "fade-out-backdrop");
    setTimeout(() => {
      dialog.close();
      dialog.remove();
      this.emit("closed");
      this.cleanup();
    }, 300);
  }

  cleanup() {
    this.eventListeners = {};
    document.removeEventListener("wheel", preventScroll);
    document.removeEventListener("touchmove", preventScroll);
  }

  resize({ height }: { height: string }) {
    this.options.height = height;
    this.dialog.style.height = this.options.height;
  }

  on(event: EventName, listener: () => void): void {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(listener);
  }

  private emit(event: EventName): void {
    const listeners = this.eventListeners[event];
    if (listeners) {
      listeners.forEach((listener) => listener());
    }
  }
}

const AA_PRODUCT_MAP_ABBREVIATION_FULL_NAME = {
  exp: "Explorer/Buying Power",
  rvl: "Payments",
  trd: "Trade",
  lse: "Lease",
  drv: "TestDrive+",
  apv: "Approve",
};

export function getProductFullName(nameAbbreviation: string) {
  return AA_PRODUCT_MAP_ABBREVIATION_FULL_NAME[nameAbbreviation];
}

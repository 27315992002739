import autocomplete from "autocompleter";
import Emittery from "emittery";

type Item = {
  label: undefined;
  id: string;
  ymmt: string;
  year: string;
  make: string;
  model: string;
  trim: string;
};

const searchYMMT = async (
  query: string
): Promise<{
  hits: {
    hits: {
      _id: string;
      _source: {
        year: string;
        make: string;
        model: string;
        trim: string;
        ymm: string;
      };
    }[];
  };
}> => {
  const search_url = window.location.protocol + ES_URL;
  const current_year = new Date().getFullYear();
  const q = {
    size: 20,
    query: {
      bool: {
        must_not: [{ term: { "year.raw": (current_year + 1).toString() } }, { term: { "year.raw": (current_year + 2).toString() } }, { term: { "year.raw": (current_year + 3).toString() } }],
        must: { match: { all_fields: { fuzziness: "AUTO:5,8", query, operator: "and" } } },
      },
    },
  } as any;
  if (COUNTRY !== "CA") {
    q.query.bool.must_not.push({ term: { "country.raw": "CA" } });
  }

  const response = await fetch(search_url, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      Authorization: "Basic " + btoa("turncar-two-snap-access:bximuxmt0fmectt6fucw89f7fhd3nshe"),
    },
    body: JSON.stringify(q),
  });
  if (!response?.ok) {
    throw new Error("Plugin error: " + response?.statusText);
  }
  return response.json();
};

export const installTypeahead = (selector?: string) => {
  const emitter = new Emittery<{ query: string; select: Item }>();
  const input_selectors = "#tradepending-vehicle-typeahead, .tradepending-vehicle-typeahead";
  const input = document.querySelector<HTMLInputElement>(selector ?? input_selectors);
  const clearButton = document.querySelector<HTMLElement>(".tp-typeahead-clear");
  if (!input) {
    return emitter;
  }

  // By default autocompleter will create the container at the document.body level.
  // But for backwards compatibility with dealer's CSS, we make it a sibling of the input
  const container = document.createElement("div");
  input?.parentElement.appendChild(container);
  autocomplete<Item>({
    input,
    debounceWaitMs: 100,
    minLength: 1,
    className: "tp-tt-suggestions",
    disableAutoSelect: true,
    fetch: async (query, callback) => {
      emitter.emit("query", query);
      const data = await searchYMMT(query);
      const vehicles: any = [];
      data.hits.hits.forEach((vehicle) => {
        const v = vehicle._source;
        const ymmt = v.ymm + " " + v.trim;
        const car = { id: vehicle._id, ymmt, year: v.year, make: v.make, model: v.model, trim: v.trim };
        vehicles.push(car);
      });
      callback(vehicles);
    },
    onSelect: (item) => {
      emitter.emit("select", item);
    },
    render: (item, query) => {
      const div = document.createElement("div");
      div.classList.add("tp-tt-suggestion");
      const markedText = highlightMatches(item.ymmt, query);
      div.innerHTML = `<p style="white-space: normal;">${markedText}</div>`; // nest it under a <p> to preserver compatibility with CSS from old typeahead
      return div;
    },
    customize: (inputElement, inputRect, containerElement, maxHeight) => {
      // Check if anything between
      let isInlineBlock = false;
      let parent = containerElement.parentElement;

      // Some sites, like the sales demo site, set #trade-pending-widget to inline-block, which messes up the positioning
      // Ideally we'd put the container directly on the body, so it wouldn't matter, but some sites have styles that expect it to be elsewhere
      while (parent.id !== "tradepending-container") {
        if (window.getComputedStyle(parent).display === "inline-block") {
          isInlineBlock = true;
          break;
        }
        parent = parent.parentElement;
      }
      if (isInlineBlock) {
        containerElement.style.left = null;
        containerElement.style.top = null;
      } else {
        containerElement.style.top = null;
      }
    },
    container,
  });
  if (clearButton) {
    clearButton.addEventListener("click", function () {
      input.value = "";
      input.focus();
    });
  }
  return emitter;
};

function escapeHTML(str) {
  return str.replace(/[&<>"']/g, (match) => {
    const escape = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;",
    };
    return escape[match];
  });
}

// Replace all occurrences of the query in the text with <mark> tags so that we can highlight the matches
function highlightMatches(text: string, query: string) {
  const sanitizedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(sanitizedQuery, "gi");
  return text.replace(regex, (match) => `<mark>${escapeHTML(match)}</mark>`);
}

import { getStyles, getWidgetTemplate } from "./widget";

type Settings = {
  chat_script?: string;
  departments: string[];
  inside_hours?: boolean;
  live_chat_widget_chime_when_opening: "0" | "1";
  live_chat_widget_enabled: "0" | "1";
  live_chat_widget_color: string;
  live_chat_widget_img: string;
  live_chat_widget_link?: string;
  live_chat_widget_message: string;
  live_chat_widget_open_automatically: "live_chat_widget_open_immediately" | "live_chat_widget_open_after_five_secs";
  live_chat_widget_position: string;
  live_chat_widget_show_outside_store_hours?: "0" | "1";
  show_chat: boolean;
  store_name: string;
  text_widget_chime_when_opening?: boolean;
  text_widget_color: string;
  text_widget_open_automatically: string;
  text_widget_enabled: boolean;
  text_widget_position: string;
  website?: string;
};

type Department = "sales" | "service";

export function hasLegacyChat() {
  const scripts = document.getElementsByTagName("script");
  for (const script of Array.from(scripts)) {
    if (script.src?.startsWith("https://snapcellvideos.s3.amazonaws.com/js/snapcell-live-chat-widget")) {
      return true;
    }
  }
  return false;
}

export function installSnapcellChat(showLocations?: boolean | string) {
  const CAN_LOCATION = Boolean(document.currentScript?.getAttribute("data-show-locations") || showLocations);

  // This flag allow us to alternate between the real API and localhost.
  const USE_LOCALHOST = location.href.match(/[?&]snapcell-use-localhost=/);
  let CHAT = false;

  // NEW SETTINGS
  const HOST = USE_LOCALHOST ? "http://localhost:3002" : "https://dashboard.snapcell.us.com";
  let SITE_URL = USE_LOCALHOST ? "google.com" : location.origin;
  const WIDGET_URL = HOST + "/api/franchises/franchises_widget?site_url=" + SITE_URL + (CAN_LOCATION ? "&data_show_locations=true" : "");
  const TOKEN = "eslr84hN8sh3erNshui48sn_nsnNNserofe78Nfern_firh98nfhEisheiOhreN3nrUnfihreN_snfEnf8V23n";
  const MSG_URL = HOST + "/api/customers/create_from_text_widget";
  let CAN_PING = false;
  let CUSTOMER_ID = null;
  let CURRENT_VIEW = "chat";
  let DEFAULT_COLOR_RGBA = "";
  let DEFAULT_COLOR = "";
  let TOGGLE = false;
  let LOADING = false;
  let SHIME = false;
  let SETTINGS: Settings = null;
  let ALL_SETTINGS: Settings[] = null;
  let CHAT_SCRIPT = null;
  let CHAT_DEPARTMENT: Department;
  const UPDATE_CUSTOMER_PHONE = HOST + "/api/customers/:id/update_telephone_from_text_widget";

  const buildStyle = (definition: string) => {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(definition));
    return style;
  };

  const appendStyles = () => {
    const styleString = getStyles(DEFAULT_COLOR, DEFAULT_COLOR_RGBA);
    getIframe().document.body.appendChild(buildStyle(styleString));
  };

  const buildWidget = () => {
    const widget = document.createElement("div");
    widget.style.position = "fixed";
    widget.style.zIndex = "99999";
    widget.innerHTML = getWidgetTemplate();
    return widget;
  };

  const addIframe = (html: HTMLElement) => {
    // Make sure we haven't already initialized the script
    let iframe = document.getElementById("snapcell_iframe_id") as HTMLIFrameElement;
    if (iframe) {
      console.warn("snapcell already installed");
      return;
    }
    iframe = document.createElement("iframe");
    const body = document.createElement("body");
    body.appendChild(html);
    iframe.id = "snapcell_iframe_id";
    iframe.allowFullscreen = true;

    const style = document.createElement("style");
    style.innerHTML = `
      #snapcell_iframe_id {
        display: initial!important;
        width: 100%!important;
        height: 100%!important;
        border: none!important;
        position: absolute!important;
        bottom: 0!important;
        right: 0!important;
        background: transparent!important;
      }
      #snapcell_container {
        width: 85px;
        height: 85px;
        z-index: 99999!important;
        display: none;
        position: fixed!important;
        bottom: 0!important;
        right: 0!important;
      }
    `;
    document.head.appendChild(style);

    const holder = document.createElement("div");
    holder.id = "snapcell_container";

    holder.appendChild(iframe);
    document.body.appendChild(holder);

    setTimeout(() => {
      getIframe().document.body.appendChild(html);
    }, 100);
  };

  const appendWidget = () => {
    const snapcellWidget = buildWidget();
    addIframe(snapcellWidget);
  };

  const toggleForm = (sender) => {
    if (sender.target.id === "snapcell_chat") {
      localStorage["snapcell-customer-closed-chat"] = null;
    }
    snapcellToggle();
  };

  const snapcellToggle = () => {
    const settings = SETTINGS;

    let default_color = "#f29052";

    if (settings?.text_widget_enabled) {
      default_color = settings.text_widget_color || default_color;
    } else if (settings?.live_chat_widget_color) {
      default_color = settings.live_chat_widget_color || default_color;
    }

    if (TOGGLE) {
      hideChat();
      $$$("snapcell_close_button").style.display = "none";
      $$("snapcell_chat").style.backgroundImage = "url(" + settings.live_chat_widget_img + ")";
      $$$("snapcell_close_button").classList.remove("scale_2");
    } else {
      showChat();
      $$$("snapcell_close_button").style.display = "flex";
      $$$("snapcell_close_button").style.background = default_color;
      $$("snapcell_chat").style.backgroundImage = "";
      setTimeout(() => {
        $$$("snapcell_close_button").classList.add("scale_2");
      }, 10);
    }
    return TOGGLE;
  };

  const hideChat = () => {
    localStorage["snapcell-customer-closed-chat"] = addHours(1);
    TOGGLE = false;
    $$("snapcell_chat").classList.add("chat-animation");
    if (CHAT) {
      $$("snapcell_content_chat").style.display = "none";
    } else {
      $$("snapcell_container_inside").style.display = "none";
    }

    redimention();
  };

  const showChat = (chime?: boolean | number) => {
    TOGGLE = true;
    if (chime) {
      $$<HTMLMediaElement>("chimesound").play();
    }
    if (CHAT) {
      if (CUSTOMER_ID) {
        setTimeout(() => {
          $$$("snapcell_main_content").scrollTop = 5000000;
        }, 1);
      }
      $$("snapcell_content_chat").style.display = "block";
    } else {
      $$("snapcell_container_inside").style.display = "block";
    }
    $$("snapcell_chat").classList.remove("chat-animation");
    hidePrompt();

    redimention();
  };

  const redimention = () => {
    const settings = SETTINGS;
    let show = true;
    let width = "85px";
    let height = "85px";

    const showBubble = localStorage["snapcell-customer-closed"];
    const neverShowBubble = settings.text_widget_open_automatically === "text_widget_open_never";

    if (!neverShowBubble && (showBubble === undefined || Date.parse(showBubble) < Date.now())) {
      width = "228px";
      height = 130 + $$$("snapcell_prompt_card").offsetHeight + "px";
    }

    if (CHAT && $$("snapcell_content_chat").style.display === "none") {
      show = false;
    } else if (!CHAT && ($$("snapcell_container_inside").style.display === "" || $$("snapcell_container_inside").style.display === "none")) {
      show = false;
    }

    if (CHAT && show) {
      width = parseInt($$("snapcell_content_chat").style.width, 10) + 80 + "px";
    } else if (show) {
      width = "350px";
    }

    if (CHAT && show) {
      let contentHeight = window.innerHeight;
      if ($$("snapcell_content_chat")) {
        const sizes = $$("snapcell_content_chat").getBoundingClientRect();
        const top = Math.ceil(sizes.top);
        if (top > 0) {
          contentHeight -= top;
        }
      }

      height = contentHeight + "px";
    } else if (show) {
      let contentHeight = window.innerHeight;
      if ($$("snapcell_container_inside")) {
        const sizes = $$("snapcell_container_inside").getBoundingClientRect();
        const top = Math.ceil(sizes.top);
        if (top > 0) {
          contentHeight -= top;
        }
      }
      height = contentHeight + "px";
    }

    document.getElementById("snapcell_container").style.width = width;
    document.getElementById("snapcell_container").style.height = height;

    fixHeight(height, show);
  };

  const fixHeight = (height, show) => {
    if (CHAT && show) {
      let contentHeight = window.innerHeight;
      if ($$("snapcell_content_chat")) {
        const sizes = $$("snapcell_content_chat").getBoundingClientRect();
        const top = Math.ceil(sizes.top);
        if (top > 0) {
          contentHeight -= top - 25;
        }
      }
      height = contentHeight + "px";
    } else if (show) {
      let contentHeight = window.innerHeight;
      if ($$("snapcell_container_inside")) {
        const sizes = $$("snapcell_container_inside").getBoundingClientRect();
        const top = Math.ceil(sizes.top);
        if (top > 0) {
          contentHeight -= top - 25;
        }
      }
      height = contentHeight + "px";
    }

    document.getElementById("snapcell_container").style.height = height;
  };

  const getIframe = () => {
    const iframe = document.getElementById("snapcell_iframe_id") as HTMLIFrameElement;
    return iframe.contentWindow;
  };

  const $$ = <T extends HTMLElement>(id: string): T | undefined => {
    const element = getIframe().document.getElementById(id);
    return element ? (element as T) : undefined;
  };

  const $$$ = (name: string) => {
    return getIframe().document.getElementsByClassName(name)[0] as HTMLElement;
  };

  const selectClass = (className: string) => {
    return getIframe().document.getElementsByClassName(className)[0] as HTMLElement;
  };

  const parseSettings = (response: string) => {
    return JSON.parse(response);
  };

  const displayWidget = (settings: Settings) => {
    // Checking if livestream, sms, and chat are all disabled
    if (settings && parseInt(settings.live_chat_widget_enabled) === 0 && !settings.text_widget_enabled && !settings.show_chat) {
      // The widget will not be shown.
      return false;
    }
    // If it reaches this point, it means either livestream, sms, or chat,
    // and we might show the widget depending on if the store is opened or not.

    // Checking if it is outside hours and the widget is set to not show outside hours
    if (settings.inside_hours === false && parseInt(settings.live_chat_widget_show_outside_store_hours) === 0) {
      // It is not inside hours and the widget is set to not show outside hours.
      // We should not display the widget.
      return false;
    }

    // If none of the above conditions are met, the widget will be displayed.
    return true;
  };

  const settingsReset = () => {
    CURRENT_VIEW = "chat";
    TOGGLE = false;

    $$("snapcell_widget").style.display = "block";
    $$("snapcell_select_location").style.display = "none";

    // position
    $$("snapcell_container_inside").classList.add("text_widget_display_right");
    $$$("snapcell_prompt_message_bubble").classList.add("snapcell_prompt_message_position_right");
    $$("snapcell_chat_close").classList.add("snapcell_chat_close_right");

    // buttons
    $$("send1").style.display = "block";
    $$("snapcell_send_chat_button_sms").style.display = "block";
    $$("send").classList.remove("only");
    $$("send").style.display = "block";
    $$("snapcell_send_chat_button_video").style.display = "block";
    if ($$$("snapcell_sms_link")) {
      $$$("snapcell_sms_link").style.display = "block";
    }
    if ($$$("snapcell_sms_video_link")) {
      $$$("snapcell_sms_video_link").style.display = "block";
    }
    $$("snapcell_mess_link_button").style.display = "block";

    $$$("snapcell_prompt_text").innerHTML = "";
    localStorage["snapcell-chat-link"] = null;

    $$("department").innerHTML = "";
    $$("snapcell_department").innerHTML = "";
    $$("store_name").innerText = "";

    $$("snapcell_sms_video_sent").style.display = "flex";
    $$("snapcell_message_sent").style.display = "flex";

    $$$("snapcell_header_text").innerText = "";
  };

  const applySettings = (settings: Settings) => {
    SETTINGS = settings;
    if (settings === undefined) {
      return false;
    }

    try {
      if (settings.website) {
        SITE_URL = settings.website;
      }
    } catch (e) {
      console.log(e);
    }

    const departments = settings.departments && settings.departments.length > 0 ? settings.departments : [];

    // Turn off widget
    if (!displayWidget(settings)) {
      $$("snapcell_widget").style.display = "none";
      return;
    }

    $$("snapcell_widget").style.display = "block";

    if (settings.text_widget_enabled) {
      $$("snapcell_chat_form").style.background = settings.text_widget_color || "orange";
    } else {
      $$("snapcell_chat_form").style.background = settings.live_chat_widget_color || "orange";
    }

    // Widget custom chat colors
    const default_color = "#f29052";

    if (settings.text_widget_enabled) {
      $$("snapcell_chat").style.border = "3px solid " + (settings.text_widget_color || default_color);
      selectClass("snapcell-chat-close").style.background = settings.text_widget_color || default_color;
      setPulseEffect(settings.text_widget_color || default_color);
    } else {
      $$("snapcell_chat").style.border = "3px solid " + (settings.live_chat_widget_color || default_color);
      selectClass("snapcell-chat-close").style.background = settings.live_chat_widget_color || default_color;
      setPulseEffect(settings.live_chat_widget_color || default_color);
    }

    // Widget position
    if (settings.text_widget_enabled) {
      if (settings.text_widget_position === "text_widget_display_right") {
        $$("snapcell_container_inside").classList.add("text_widget_display_right");
        $$$("snapcell_prompt_message_bubble").classList.add("snapcell_prompt_message_position_right");
        $$("snapcell_chat_close").classList.add("snapcell_chat_close_right");
      } else {
        $$("snapcell_container_inside").classList.add("text_widget_display_left");
        $$("snapcell_chat").classList.add("snapcell_chat_left");
        $$$("snapcell_prompt_message_bubble").classList.add("snapcell_prompt_message_position_left");
        document.getElementById("snapcell_container").style.left = "0px";
      }
    } else if (settings.live_chat_widget_position === "live_chat_widget_display_right") {
      $$("snapcell_widget").classList.add("live_chat_widget_display_right");
      $$$("snapcell_prompt_message_bubble").classList.add("snapcell_prompt_message_position_right");
      $$("snapcell_chat_close").classList.add("snapcell_chat_close_right");
    } else {
      $$("snapcell_widget").classList.add("live_chat_widget_display_left");
      $$$("snapcell_prompt_message_bubble").classList.add("snapcell_prompt_message_position_left");
      document.getElementById("snapcell_container").style.left = "0px";
    }

    if (!settings.text_widget_enabled) {
      $$("send1").style.display = "none";
      $$("snapcell_send_chat_button_sms").style.display = "none";
      $$("send").classList.add("only");
      hideClass(".snapcell_sms_link");
    }

    if (!parseInt(settings.live_chat_widget_enabled)) {
      $$("send").style.display = "none";
      $$("snapcell_send_chat_button_video").style.display = "none";
      hideClass(".snapcell_sms_video_link");
    }

    if (!settings.text_widget_enabled && settings.live_chat_widget_message) {
      $$$("snapcell_prompt_text").innerHTML = settings.live_chat_widget_message;
      $$("send").classList.add("only");
    } else if (!parseInt(settings.live_chat_widget_message) && settings.live_chat_widget_message) {
      $$$("snapcell_prompt_text").innerHTML = settings.live_chat_widget_message;
    } else if (settings.live_chat_widget_message === "") {
      hidePrompt();
    }

    // Widget header message
    if (settings.live_chat_widget_message) {
      $$$("snapcell_headline").innerHTML = settings.live_chat_widget_message;
    }

    if (settings.live_chat_widget_link) {
      const clean_link = settings.live_chat_widget_link.replace("https://www.snapcell.co", HOST).replace("customer_request", "customer_request_create");
      localStorage["snapcell-chat-link"] = clean_link;
    } else {
      localStorage["snapcell-chat-link"] = null;
    }

    // Widget open chat config

    if (settings.text_widget_enabled) {
      setOpenState(settings);
    } else {
      setChatOpenState(settings);
    }

    // Widget chat image
    $$("snapcell_chat").style.backgroundImage = "url(" + settings.live_chat_widget_img + ")";

    if (departments && departments.length > 1) {
      let can_display = false;
      $$("snapcell_department").append(buildInitialDepartmentOption());
      for (const item of departments) {
        if (item !== "") {
          $$("department").append(buildDepartmentOption(item));
          $$("snapcell_department").append(buildDepartmentOption(item));
          can_display = true;
        }
      }
      if (can_display) {
        $$("department").style.display = "block";
        $$("snapcell_department").setAttribute("required", "true");
        $$("snapcell_department").style.display = "block";
      }
    } else {
      $$("department").style.display = "none";
      $$("snapcell_department").style.display = "none";
    }

    if (settings.store_name) {
      $$("store_name").innerText = settings.store_name;
    }

    if (settings.text_widget_enabled) {
      SHIME = settings.text_widget_chime_when_opening;
    } else if (parseInt(settings.live_chat_widget_enabled)) {
      SHIME = setChime(settings.live_chat_widget_chime_when_opening);
    }

    if (settings.chat_script) {
      CHAT_SCRIPT = settings.chat_script;
    }
  };

  const runChatScript = () => {
    const script = document.createElement("script");
    script.innerHTML = CHAT_SCRIPT;
    script.type = "text/javascript";
    getIframe().document.body.appendChild(script);
  };

  const buildInitialDepartmentOption = () => {
    const option = document.createElement("option");
    option.value = "";
    option.innerHTML = "-- Select Department --";
    return option;
  };

  const buildDepartmentOption = (department: string) => {
    const option = document.createElement("option");
    option.value = department;
    option.innerHTML = department;
    return option;
  };

  const buildOption = (name: string, id: string) => {
    const option = document.createElement("option");
    option.value = id;
    option.innerHTML = name;
    return option;
  };

  // Create and add pulse effect
  const setPulseEffect = (color) => {
    const lightColor = color + "aa";
    const zeroColor = color + "00";
    const cssKeyframe = "{0% {box-shadow: 0 0 0 0 " + lightColor + ";} 70% {box-shadow: 0 0 0 15px " + zeroColor + ";} 100% {box-shadow: 0 0 0 0 " + zeroColor + ";}}";
    const rule = "@keyframes pulse-chat " + cssKeyframe;
    const style = document.createElement("style");
    style.innerText = rule;
    getIframe().document.head.appendChild(style);
  };

  // chat open start
  const setChatOpenState = (settings: Settings) => {
    const chime = setChime(settings.live_chat_widget_chime_when_opening);
    if (settings.live_chat_widget_open_automatically === "live_chat_widget_open_immediately") {
      showChat(chime);
    } else if (settings.live_chat_widget_open_automatically === "live_chat_widget_open_after_five_secs") {
      setTimeout(() => {
        showChat(chime);
      }, 5 * 1000);
    }
  };

  const setOpenState = (settings: Settings) => {
    const showBubble = localStorage["snapcell-customer-closed"];
    if (settings.text_widget_open_automatically === "text_widget_open_immediately") {
      setTimeout(() => {
        addPromptBuble(showBubble);
      }, 10);
    } else if (settings.text_widget_open_automatically === "text_widget_open_after_five_secs") {
      setTimeout(() => {
        addPromptBuble(showBubble);
      }, 5 * 1000);
    }
  };

  const hideControls = () => {
    hideElements(["name", "last_name", "telephone", "vehicle_of_interest", "send", "send1", "opt_in_disclaimer", "prompt", "error_form", "department"]);

    $$("snapcell_chat_form").style.height = "370px";
  };

  const hideElements = (ids) => {
    for (const id of ids) $$(id).style.display = "none";
  };

  const messageSent = (xhr) => {
    console.log(xhr.response);

    clearChat();
    hideControls();
    $$("notice").style.display = "block";

    const message = JSON.parse(xhr.response)["message"];
    $$("notice").innerText = message;
  };

  const errorSendingMessage = (xhr) => {
    logErrorSafely(xhr);

    clearChat();
    hideControls();
    $$("error").style.display = "block";

    const store_phone = localStorage["snapcell-store-phone"];
    $$("error").innerText = "Unfortunately there has been an error. " + "Please contact your dealership directly at this number: " + store_phone;
  };

  const show_error_form = () => {
    $$("error_form").style.display = "block";
    $$("prompt").style.display = "none";
    setTimeout(() => {
      $$("error_form").style.display = "none";
      $$("prompt").style.display = "block";
    }, 5000);
  };

  const addLastName = () => {
    const name = $$<HTMLInputElement>("name").value;
    const firstName = name.split(" ");
    if (firstName.length > 1) {
      $$<HTMLInputElement>("first_name").value = firstName[0];
      $$<HTMLInputElement>("last_name").value = name.replace(firstName[0], "").trim();
    } else {
      $$<HTMLInputElement>("first_name").value = $$<HTMLInputElement>("name").value;
    }
  };

  const sendMessage = () => {
    if (LOADING) {
      return false;
    }

    LOADING = true;

    if (validate()) {
      show_error_form();
      LOADING = false;
      return;
    }

    addLastName();

    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        LOADING = false;
        if (CHAT_SCRIPT) {
          runChatScript();
        }
        return messageSent(xhr);
      } else {
        LOADING = false;
        return errorSendingMessage(xhr);
      }
    };
    xhr.open("POST", MSG_URL, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    const json = JSON.stringify(messageParams());
    xhr.send(json);
  };

  const sendLiveVideo = () => {
    if (LOADING) {
      return false;
    }

    LOADING = true;
    if (validate()) {
      show_error_form();
      LOADING = false;
      return;
    }

    if (CHAT_SCRIPT) {
      runChatScript();
    }

    const form = document.createElement("form");
    const name = createInput("name", $$<HTMLInputElement>("name").value);
    const telephone = createInput("telephone", $$<HTMLInputElement>("telephone").value);

    const vehicle_of_interest = createInput("vehicle_of_interest", $$<HTMLInputElement>("vehicle_of_interest").value);
    form.method = "post";
    form.action = localStorage["snapcell-chat-link"];
    form.appendChild(name);
    form.appendChild(telephone);
    form.appendChild(vehicle_of_interest);
    form.style.display = "none";
    document.body.appendChild(form);
    form.submit();
  };

  const createInput = (name: string, value: string) => {
    const input = document.createElement("input");
    input.type = "text";
    input.name = name;
    input.value = value;
    return input;
  };

  const logErrorSafely = (xhr) => {
    try {
      console.log(JSON.parse(xhr.response)["error"]);
    } catch (ex) {
      console.log(ex);
    }
  };

  const clearChat = () => {
    $$<HTMLInputElement>("name").value = null;
    $$<HTMLInputElement>("first_name").value = null;
    $$<HTMLInputElement>("last_name").value = null;
    $$<HTMLInputElement>("telephone").value = null;
    $$<HTMLInputElement>("vehicle_of_interest").value = null;
    $$<HTMLInputElement>("department").value = null;
  };

  const messageParams = () => {
    const customer = {
      first_name: "",
      last_name: "",
      telephone: "",
      message: "",
      site_url: "",
      department: "",
      type: "",
      host_url: location.href,
    };

    customer.first_name = $$<HTMLInputElement>("first_name").value;
    customer.last_name = $$<HTMLInputElement>("last_name").value;
    customer.telephone = $$<HTMLInputElement>("telephone").value;
    customer.message = $$<HTMLInputElement>("vehicle_of_interest").value;
    customer.department = $$<HTMLInputElement>("department").value;
    customer.site_url = SITE_URL;
    customer.type = $$("type") != null ? $$<HTMLInputElement>("type").value : "";

    const params = {
      customer,
    };
    return params;
  };

  const setChime = (chime: "0" | "1") => !!parseInt(chime);

  // Get widget settings.
  const fetchSettings = (next: (settings: Settings[]) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        const settings = parseSettings(xhr.response);
        // This could help with debugging too.
        localStorage["snapcell-settings"] = JSON.stringify(settings);
        next(settings);
      } else {
        console.error("Failed to get SnapCell widget settings.");
        console.error(xhr);
      }
    };
    xhr.open("GET", WIDGET_URL);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.send();
  };

  const validate = () => {
    const presence_elements = ["name", "telephone", "vehicle_of_interest"];

    for (const item of presence_elements) {
      if ($$<HTMLInputElement>(item).value === "") {
        return true;
      }
    }

    return false;
  };

  const addPromptBuble = (showBubble) => {
    if (showBubble && Date.parse(showBubble) > Date.now()) {
      return false;
    }

    let enter = false;
    let timeout;
    const closeButton = "snapcell_prompt_close_button_button";

    const removeClose = () => {
      enter = false;
    };

    const showClose = () => {
      enter = true;
      $$$(closeButton).classList.add("snapcell_show");
      $$$(closeButton).classList.remove("snapcell_hide");
    };

    $$$("snapcell_prompt_message_bubble").classList.add("snapcell_show");

    $$$(closeButton).addEventListener("mouseover", () => {
      clearTimeout(timeout);
    });

    $$$(closeButton).addEventListener("mouseout", () => {
      timeout = setTimeout(() => {
        if (enter) {
          removeClose();
        }
      });
    });

    $$$("snapcell_prompt_card").addEventListener("mouseover", () => {
      clearTimeout(timeout);
      if (!enter) {
        showClose();
      }
    });

    $$$("snapcell_prompt_card").addEventListener("mouseout", () => {
      timeout = setTimeout(() => {
        if (enter) {
          removeClose();
        }
      }, 100);
    });

    $$$("snapcell_prompt_card").addEventListener("click", () => {
      $$$("snapcell_prompt_message_bubble").classList.add("snapcell_hide");
      setTimeout(() => {
        showChat();
        hidePrompt();
      }, 500);
    });

    $$$(closeButton).addEventListener("click", () => {
      $$$("snapcell_prompt_message_bubble").classList.add("snapcell_hide");
      setTimeout(() => {
        hidePrompt();
      }, 500);
    });
  };

  const hidePrompt = () => {
    localStorage["snapcell-customer-closed"] = addHours(1);
    $$$("snapcell_prompt_message_bubble").classList.add("snapcell_none");
    redimention();
  };

  const addField = (id: string) => {
    if (!$$(id)) {
      return false;
    }

    const required = ($$(id).parentNode as HTMLElement).classList.contains("snapcell_required");

    $$(id).addEventListener("focus", function () {
      (this.parentNode as HTMLElement).classList.add("snapcell_selected");
      const label = ($$(id).parentNode as HTMLElement).getElementsByTagName("label");
      if (label[0]) {
        label[0].style.color = DEFAULT_COLOR;
      }
      if (required) {
        (this.parentNode as HTMLElement).classList.remove("snapcell_error");
      }
    });

    $$(id).addEventListener("blur", function () {
      if ($$<HTMLInputElement>(id).value === "") {
        (this.parentNode as HTMLElement).classList.remove("snapcell_selected");
        const label = ($$(id).parentNode as HTMLElement).getElementsByTagName("label");
        label[0].style.color = "";
        if (required) {
          (this.parentNode as HTMLElement).classList.add("snapcell_error");
        }
      }
    });
  };

  const fixSize = () => {
    if (window.innerHeight < 750) {
      let contentHeight = window.innerHeight - 250;
      if (CUSTOMER_ID) {
        contentHeight = window.innerHeight - 320;
      }
      $$$("snapcell_main_content").style.height = contentHeight + "px";
    } else if (CUSTOMER_ID) {
      $$$("snapcell_main_content").style.height = "400px";
    } else {
      $$$("snapcell_main_content").style.height = "480px";
    }

    let widthSize = 300;
    const maximum = 400;
    const minimum = 200;

    if (window.innerWidth > minimum) {
      widthSize = window.innerWidth - 90;

      if (widthSize > maximum) {
        widthSize = maximum;
      }
    } else {
      widthSize = minimum;
    }

    $$("snapcell_content_chat").style.width = widthSize + "px";

    redimention();
  };

  // Chat methods
  const applyChatSettings = (settings: Settings) => {
    // Turn off widget
    if (!displayWidget(settings)) {
      $$("snapcell_widget").style.display = "none";
      return;
    }

    $$("snapcell_widget").style.display = "block";

    // Widget custom chat colors
    let default_color = "#fc7714";
    let position;
    let default_rgba_color;

    if (settings?.text_widget_enabled) {
      default_color = settings.text_widget_color || default_color;
    } else if (settings) {
      default_color = settings.live_chat_widget_color || default_color;
    }

    default_rgba_color = hexToRGB(default_color, 0.9);
    DEFAULT_COLOR_RGBA = default_rgba_color;
    DEFAULT_COLOR = default_color;

    $$("id_snapcell_header").style.background = default_color;
    if ($$("snapcell_send_chat_button")) {
      $$("snapcell_send_chat_button").style.background = default_color;
    }

    // Widget position
    if (settings?.text_widget_enabled) {
      if (settings.text_widget_position === "text_widget_display_right") {
        position = "right";
      } else {
        position = "left";
      }
    } else if (settings?.live_chat_widget_position === "live_chat_widget_display_right") {
      position = "right";
    } else {
      position = "left";
    }

    if (position === "right") {
      $$$("snapcell_content").classList.add("snapcell_content_display_right");
    } else if (position === "left") {
      $$$("snapcell_content").classList.add("snapcell_content_display_left");
    }

    if (settings?.store_name) {
      $$$("snapcell_header_text").innerText = settings.store_name;
    }

    if (settings?.live_chat_widget_img) {
      $$("snapcell_header_icon").style.display = "inline";
      $$("snapcell_header_icon").style.backgroundImage = "url(" + settings.live_chat_widget_img + ")";
    } else {
      $$("snapcell_header_icon").style.display = "none";
    }

    if (settings && !settings.show_chat) {
      changeView();
      $$("snapcell_mess_link_button").style.display = "none";
    }
  };

  const messageChatParams = () => {
    const customer = {
      first_name: "",
      last_name: "",
      message: "",
      site_url: "",
      type: "chat",
      department: "",
      host_url: location.href,
    };

    customer.first_name = $$<HTMLInputElement>("snapcell_first_name_hide").value;
    customer.last_name = $$<HTMLInputElement>("snapcell_last_name_hide").value;
    customer.type = $$<HTMLInputElement>("snapcell_type").value;
    customer.message = $$<HTMLInputElement>("message_chat").value;
    customer.site_url = SITE_URL;
    customer.department = CHAT_DEPARTMENT;

    const params = {
      customer,
    };
    return params;
  };

  const validateChat = () => {
    const presence_elements = ["message_chat"];

    for (const item of presence_elements) {
      if ($$<HTMLInputElement>(item).value === "") {
        ($$(item).parentNode as HTMLElement).classList.add("snapcell_error");
        return true;
      }
    }

    return false;
  };

  const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  };

  const showLoader = (show) => {
    if (show) {
      LOADING = true;
      hideClass(".snapcell_button_text");
      showClass(".snapcell_loader");
    } else {
      LOADING = false;
      showClass(".snapcell_button_text");
      hideClass(".snapcell_loader");
    }
  };

  const sendMessageChat = () => {
    if (LOADING) {
      return false;
    }

    showLoader(true);

    if (validateChat()) {
      showLoader(false);
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        $$("snapcell_message_sent").style.display = "none";
        $$("snapcell_message_sent2").style.display = "none";
        $$("snapcell_message_chat").style.display = "block";
        $$<HTMLInputElement>("message_chat").value = "";
        const response = JSON.parse(xhr.response);
        if (response.customer) {
          localStorage["snapcell-customer-id"] = response.customer;
          CUSTOMER_ID = response.customer;
          readMessage(false, true);
          CAN_PING = true;
          if (CHAT_SCRIPT) {
            runChatScript();
          }
        }
        showLoader(false);
        fixSize();
      } else {
        showLoader(false);
        return errorSendingMessage(xhr);
      }
    };
    xhr.open("POST", MSG_URL, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    const json = JSON.stringify(messageChatParams());
    xhr.send(json);
  };

  const chatParams = () => {
    const params = {
      body: $$<HTMLInputElement>("message_chat").value,
    };
    return params;
  };

  const pingMessage = () => {
    CAN_PING = false;
    const url = HOST + "/api/customers/" + CUSTOMER_ID + "/messages/ping_message";
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        // loop
        const response = JSON.parse(xhr.response);
        if (response.update !== null) {
          CAN_PING = true;

          if (response.update) {
            readMessage(false, false);
          }
        }
      }
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send();
  };

  const readMessage = (playSound, firstMessage) => {
    showLoading();
    const url = HOST + "/api/customers/" + CUSTOMER_ID + "/messages/read_message";
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        // loop
        const response = JSON.parse(xhr.response);

        if (firstMessage) {
          response.messages = [response.messages[0]];
        }

        const content = $$$("snapcell_message_content");
        const outerContent = document.createElement("div");
        outerContent.classList.add("snapcell_message_list");
        if (response.messages.length > 0) {
          response.messages.forEach((item) => {
            if (item.media_type === "video_request") {
              return;
            }
            const contentChild = document.createElement("div");
            const contentChildTime = document.createElement("div");
            const contentChildSender = document.createElement("div");
            if (item.from_type === "Customer") {
              contentChild.classList.add("snapcell_message_item_from");
              contentChildTime.classList.add("snapcell_message_item_hr_from");
              contentChild.setAttribute("id", "snapcell_message_item_from_" + item.id);
            } else {
              contentChild.classList.add("snapcell_message_item_to");
              contentChildTime.classList.add("snapcell_message_item_hr_to");
              contentChildSender.classList.add("snapcell_message_item_sender_to");
              contentChild.setAttribute("id", "snapcell_message_item_to_" + item.id);
            }
            const currentTime = new Date(item.created_at);

            let description = item.body;
            let dataResult = null;
            let dataButtons = null;
            let dataButtonsInfo = null;
            let dataLink = null;
            const elementLink = document.createElement("a");
            const contentAnswers = document.createElement("div");
            let requestTelephone = null;
            contentAnswers.className = "snapcell_answers";
            contentAnswers.setAttribute("id", "snapcell_answers_" + item.id);

            if (isJson(description)) {
              dataResult = JSON.parse(description);
              description = dataResult.name;

              if (description.includes("::BUTTONS::")) {
                const elementButtons = description.replace("::BUTTONS::", "").split(" ");
                dataButtonsInfo = [];
                for (const button of elementButtons) {
                  dataButtonsInfo.push({
                    id: Math.random(),
                    name: button,
                  });
                }
                dataButtons = document.createElement("div");
                dataButtons.className = "snapcell_buttons";
                dataButtonsInfo.forEach((btn) => {
                  const contentButton = document.createElement("button");
                  contentButton.className = "snapcell_button";
                  contentButton.id = "snapcell_button_" + btn.id;
                  contentButton.innerHTML = btn.name;
                  dataButtons.append(contentButton);
                });
              }

              if (description.includes("::LINK::")) {
                const elLink = description.replace("::LINK::", "");
                elementLink.setAttribute("href", elLink);
                elementLink.target = "_blank";
                elementLink.classList.add("snapcell_element_link");
                elementLink.innerHTML = elLink;
                dataLink = elementLink;
              }

              if (description.includes("::REQUEST_PHONE::")) {
                description = description.substring(0, description.lastIndexOf("::CONFIRMATION_MESSAGE::"));
                description = description.replace("::REQUEST_PHONE::", "");
                requestTelephone = buildRequestPhone(dataResult.id);
              }

              if (dataResult.answers.length > 0) {
                dataResult.answers.forEach((answer) => {
                  let desc = answer.name;
                  if (desc.includes("::LABEL::")) {
                    desc = desc.substring(desc.indexOf("::LABEL::")).replace("::LABEL::", "");
                  }
                  if (desc.includes("::LINK::")) {
                    desc = desc.substring(desc.indexOf("::LINK::")).replace("::LINK::", "");
                  }
                  if (desc.includes("::REQUEST_PHONE::")) {
                    desc = desc.substring(0, desc.lastIndexOf("::CONFIRMATION_MESSAGE::"));
                    desc = desc.replace("::REQUEST_PHONE::", "");
                  }
                  const contentAnswer = document.createElement("button");
                  contentAnswer.className = "snapcell_answer";
                  contentAnswer.id = "snapcell_answer_" + answer.id;
                  contentAnswer.innerHTML = desc;
                  contentAnswers.append(contentAnswer);
                });
              }
            }

            if (dataLink) {
              // LINK
              contentChild.append(dataLink);
            } else if (dataButtons) {
              // BUTTON
              //
            } else if (requestTelephone) {
              contentChild.innerHTML = description;
              contentChild.append(requestTelephone);
            } else {
              // TEXT
              contentChild.innerHTML = description;
            }

            const timeNow = currentTime.getHours() + ":" + ("0" + currentTime.getMinutes()).slice(-2);
            contentChildTime.innerHTML = tConvert(timeNow);

            if (item.media_files && item.media_files.length > 0) {
              item.media_files.forEach((mediaFile) => {
                contentChild.appendChild(addImage(mediaFile));
              });
            }

            if (item.from_type !== "Customer") {
              let urlImage = "https://assets.snapcell.us.com/defaultlivechatwidgetimage.jpg";
              if (SETTINGS.live_chat_widget_img) {
                urlImage = SETTINGS.live_chat_widget_img;
              }
              contentChildSender.innerHTML = "<div class='sender-content'> <img class='sender-img' src=" + urlImage + ">" + item.sender_name + "</div>";
              if (playSound !== null) {
                playSound = true;
              }
            } else if (playSound !== null) {
              playSound = false;
            }

            outerContent.append(contentChildSender);
            if (dataButtons === null) {
              outerContent.append(contentChild);
            }

            if (dataButtons) {
              outerContent.append(dataButtons);
              if (dataButtonsInfo.length > 0) {
                for (const info of dataButtonsInfo) {
                  addButtonListner(info);
                }
              }
            }

            outerContent.append(contentChildTime);

            if (dataResult) {
              outerContent.append(contentAnswers);

              if (dataResult.answers.length > 0) {
                for (const answer of dataResult.answers) {
                  addAnswerListener(answer.id, answer.name);
                }
              }
            }
          });
          content.innerHTML = "";
          content.append(outerContent);

          if (playSound && SHIME) {
            $$<HTMLMediaElement>("chimesound").play();
          }
          setTimeout(() => {
            $$$("snapcell_main_content").scrollTop = 5000000;
          }, 100);
          setTimeout(() => {
            $$$("snapcell_main_content").scrollTop = 5000000;
          }, 1000);
          setTimeout(() => {
            $$$("snapcell_main_content").scrollTop = 5000000;
          }, 3000);
        }

        if (firstMessage) {
          setTimeout(() => {
            readMessage(false, false);
          }, 1000);
        }

        if (DEFAULT_COLOR_RGBA !== "") {
          const elements = getIframe().document.getElementsByClassName("snapcell_message_item_to");
          for (const element of Array.from(elements)) {
            (element as HTMLElement).style.background = DEFAULT_COLOR_RGBA;
          }
        }
      }
      hideLoading();
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send();
  };

  const addButtonListner = (data) => {
    setTimeout(() => {
      $$("snapcell_button_" + data.id).addEventListener("click", (event) => {
        if (data.name === "Chat") {
          $$("message_chat").focus();
        } else if (data.name === "Video") {
          openVideo();
        } else if (data.name === "SMS") {
          openSms();
        }
      });
    }, 1000);
  };

  const openVideo = () => {
    changeView();
  };

  const openSms = () => {
    changeView();
  };

  const addAnswerListener = (id: string, name: string) => {
    setTimeout(() => {
      $$("snapcell_answer_" + id).addEventListener("click", (event) => {
        if (name.includes("::LINK::")) {
          const link = name.replace("::LINK::", "").replace(/::LABEL::.*/, "");
          window.open(link, "_blank");
        } else {
          preAnswer(id);
        }
      });
    }, 1000);
  };

  const addImage = (url: string) => {
    const img = document.createElement("img");
    img.setAttribute("src", url);
    img.setAttribute("width", "200");
    img.setAttribute("alt", "Flower");
    return img;
  };

  const preAnswer = (id: string) => {
    const url = HOST + "/api/customers/" + CUSTOMER_ID + "/messages/answer";
    showLoading();
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        console.log(xhr);
      } else {
        hideLoading();
        return errorSendingMessage(xhr);
      }
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    const params = {
      id,
    };
    const json = JSON.stringify(params);
    xhr.send(json);
  };

  const writeMessage = () => {
    if (!CUSTOMER_ID) {
      sendMessageChat();
      return false;
    }

    const url = HOST + "/api/customers/" + CUSTOMER_ID + "/messages/write_message";
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        console.log(xhr);
      } else {
        return errorSendingMessage(xhr);
      }
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    const params = chatParams();
    const json = JSON.stringify(params);
    xhr.send(json);

    const listing = $$$("snapcell_message_list");
    const currentItem = document.createElement("div");
    currentItem.classList.add("snapcell_message_item_from");
    currentItem.classList.add("change_direction_from");
    currentItem.setAttribute("id", "currentItem");
    currentItem.innerHTML = $$<HTMLInputElement>("message_chat").value;
    listing.append(currentItem);

    setTimeout(() => {
      $$("currentItem").classList.remove("change_direction_from");
    }, 100);

    $$<HTMLInputElement>("message_chat").value = "";
    $$$("snapcell_main_content").scrollTop = 5000000;
  };

  const startPing = () => {
    setInterval(() => {
      if (CAN_PING) {
        pingMessage();
      }
    }, 3000);
  };

  const chat = () => {
    setTimeout(() => {
      fixSize();
    }, 1000);

    fixSize();

    addField("message");
    addField("message_chat");

    const customerId = localStorage["snapcell-customer-id"];
    if (customerId) {
      CUSTOMER_ID = customerId;
    }

    $$("snapcell_sms_video_sent").style.display = "none";

    if (CUSTOMER_ID) {
      CAN_PING = true;
      readMessage(null, false);
      $$("snapcell_message_sent").style.display = "none";
      $$("snapcell_message_sent2").style.display = "none";
      $$("snapcell_message_chat").style.display = "block";
    } else if (SETTINGS.departments.length > 1 && !CHAT_DEPARTMENT) {
      $$("snapcell_mess_sales_button").style.display = "flex";
      $$("snapcell_mess_service_button").style.display = "flex";
      $$("snapcell_message_chat").style.display = "none";
    } else {
      $$("snapcell_message_chat").style.display = "block";
    }

    startPing();

    window.addEventListener(
      "resize",
      (event) => {
        fixSize();
      },
      true
    );
  };

  const changeView = () => {
    if (CURRENT_VIEW === "chat") {
      $$("snapcell_message_sent").style.display = "none";
      $$("snapcell_sms_video_sent").style.display = "flex";
      $$$("snapcell_message_holder").style.display = "none";
      $$("snapcell_message_chat").style.display = "none";
      $$("snapcell_mess_link_button1").style.display = "none";
      CURRENT_VIEW = "widget";
    } else {
      CURRENT_VIEW = "chat";
      $$("snapcell_sms_video_sent").style.display = "none";
      if (CUSTOMER_ID) {
        $$$("snapcell_message_holder").style.display = "flex";
        $$("snapcell_message_chat").style.display = "flex";
        $$$("snapcell_main_content").scrollTop = 5000000;
      } else {
        $$("snapcell_message_sent").style.display = "flex";
        $$("snapcell_message_chat").style.display = "flex";
      }
    }
  };

  const setDepartment = (department: Department) => {
    CHAT_DEPARTMENT = department;
    $$("snapcell_mess_sales_button").style.display = "none";
    $$("snapcell_mess_service_button").style.display = "none";
    $$("snapcell_mess_link_button1").style.display = "none";
    $$("snapcell_message_chat").style.display = "block";
  };

  const validateSmsVideo = () => {
    const presence_elements = ["snapcell_first_name_sms", "snapcell_phone_sms", "snapcell_message_sms"];

    if (SETTINGS.departments?.length > 1) {
      presence_elements.push("snapcell_department");
    }

    let stop_here = false;

    for (const item of presence_elements) {
      if ($$<HTMLInputElement>(item).value === "") {
        ($$(item).parentNode as HTMLElement).classList.add("snapcell_error");
        stop_here = true;
      }
    }

    return stop_here;
  };

  const sendMessageVideo = () => {
    if (LOADING) {
      return false;
    }

    if (CHAT_SCRIPT) {
      runChatScript();
    }

    showLoader(true);
    if (validateSmsVideo()) {
      showLoader(false);
      return true;
    }

    const form = document.createElement("form");
    const name = createInput("name", $$<HTMLInputElement>("snapcell_first_name_sms").value);
    const telephone = createInput("telephone", $$<HTMLInputElement>("snapcell_phone_sms").value);
    const vehicle_of_interest = createInput("vehicle_of_interest", $$<HTMLInputElement>("snapcell_message_sms").value);
    const snapcell_department_sms_video = createInput("snapcell_department_sms_video", $$<HTMLInputElement>("snapcell_department").value);
    form.method = "post";
    form.action = localStorage["snapcell-chat-link"];
    form.appendChild(name);
    form.appendChild(telephone);
    form.appendChild(vehicle_of_interest);
    form.appendChild(snapcell_department_sms_video);
    form.style.display = "none";
    document.body.appendChild(form);
    form.submit();
  };

  const addSmsVideoLastName = () => {
    const name = $$<HTMLInputElement>("snapcell_first_name_sms").value;
    const firstName = name.split(" ");
    if (firstName.length > 1) {
      $$<HTMLInputElement>("snapcell_first_name_hide").value = firstName[0];
      $$<HTMLInputElement>("snapcell_last_name_hide").value = name.replace(firstName[0], "").trim();
    } else {
      $$<HTMLInputElement>("snapcell_first_name_hide").value = $$<HTMLInputElement>("snapcell_first_name_sms").value;
    }

    $$<HTMLInputElement>("snapcell_vehicle_of_interest").value = $$<HTMLInputElement>("snapcell_message_sms").value;
  };

  const messageSmsVideoParams = () => {
    const customer = {
      first_name: "",
      last_name: "",
      telephone: "",
      message: "",
      site_url: "",
      department: "",
      host_url: location.href,
    };

    customer.first_name = $$<HTMLInputElement>("snapcell_first_name_hide").value;
    customer.last_name = $$<HTMLInputElement>("snapcell_last_name_hide").value;
    customer.telephone = $$<HTMLInputElement>("snapcell_phone_sms").value;
    customer.message = $$<HTMLInputElement>("snapcell_vehicle_of_interest").value;
    customer.department = $$<HTMLInputElement>("snapcell_department").value;
    customer.site_url = SITE_URL;

    const params = {
      customer,
    };
    return params;
  };

  const messageSentSms = (xhr) => {
    console.log(xhr.response);

    $$("snapcell_message_content_success_error").style.display = "block";
    $$("snapcell_sms_video_sent").style.display = "none";
    $$("snapcell_message_content_success_error").style.display = "block";
    $$$("snapcell_message_holder").style.display = "flex";

    const message = JSON.parse(xhr.response)["message"];
    $$("snapcell_message_content_success_error").innerText = message;
  };

  const errorSendingMessageSms = (xhr) => {
    logErrorSafely(xhr);

    $$$("snapcell_message_holder").style.display = "flex";
    $$("snapcell_message_content_success_error").style.display = "block";
    $$("snapcell_sms_video_sent").style.display = "none";

    const store_phone = localStorage["snapcell-store-phone"];
    $$("snapcell_message_content_success_error").innerText = "Unfortunately there has been an error. " + "Please contact your dealership directly at this number: " + store_phone;
  };

  const sendMessageSms = () => {
    if (LOADING) {
      return false;
    }
    showLoader(true);
    if (validateSmsVideo()) {
      showLoader(false);
      return true;
    }

    addSmsVideoLastName();

    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        showLoader(false);
        if (CHAT_SCRIPT) {
          runChatScript();
        }
        return messageSentSms(xhr);
      } else {
        showLoader(false);
        return errorSendingMessageSms(xhr);
      }
    };
    xhr.open("POST", MSG_URL, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    const json = JSON.stringify(messageSmsVideoParams());
    xhr.send(json);
  };

  const hideClass = (currentClass: string) => {
    getIframe()
      .document.querySelectorAll(currentClass)
      .forEach((el) => {
        (el as HTMLElement).style.display = "none";
      });
  };

  const showClass = (currentClass: string) => {
    getIframe()
      .document.querySelectorAll(currentClass)
      .forEach((el) => {
        (el as HTMLElement).style.display = "block";
      });
  };

  const hexToRGB = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const addHours = (h: number, date = new Date()) => {
    date.setHours(date.getHours() + h);
    return date;
  };

  const hideForever = () => {
    localStorage["snapcell-hide-time"] = addHours(24);
    $$("snapcell_widget").style.display = "none";
  };

  const tConvert = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  const changeSettings = (selected) => {
    if (selected === undefined || selected === null) {
      return false;
    }

    // remove all settings
    const styles = getIframe().document.getElementsByTagName("style");
    for (const style of Array.from(styles)) {
      style.remove();
    }

    settingsReset();

    applySettings(ALL_SETTINGS[selected]);
    if (CHAT) {
      fixSize();
      chat();
      applyChatSettings(ALL_SETTINGS[selected]);
    } else {
      $$("snapcell_header_icon").style.display = "none";
    }

    appendStyles();
    redimention();
  };

  const selectLocation = () => {
    settingsReset();
    $$("snapcell_select_location").style.display = "flex";
    $$("snapcell_sms_video_sent").style.display = "none";
    $$("snapcell_message_sent").style.display = "none";
    $$("snapcell_location").style.display = "block";

    const locations = ALL_SETTINGS;
    if (locations && locations.length > 1) {
      for (let i = 0; i < locations.length; i++) {
        const name = locations[i].store_name ? locations[i].store_name : "";
        $$("snapcell_location").append(buildOption(name, String(i)));
      }
    }

    $$("snapcell_location").onchange = (evt) => {
      const target = evt.target as HTMLInputElement;
      const value = target.value;
      changeSettings(value);
    };
  };

  const addFranchise = (selected: number, location: boolean) => {
    settingsReset();
    document.getElementById("snapcell_container").style.display = "initial";
    $$("snapcell_content_chat").style.display = "none";

    applySettings(ALL_SETTINGS[selected]);

    if (CHAT) {
      fixSize();
      addField("first_name");
      addField("phone");
      addField("message");
      addField("snapcell_first_name_sms");
      addField("snapcell_phone_sms");
      addField("snapcell_message_sms");
      chat();
      applyChatSettings(ALL_SETTINGS[selected]);
    }

    appendStyles();

    redimention();

    if (location && !CUSTOMER_ID) {
      selectLocation();
    }

    $$("snapcell_chat").addEventListener("click", (event) => {
      toggleForm(event);
    });

    $$("snapcell_chat_close_1").addEventListener("click", (event) => {
      toggleForm(event);
    });

    $$("snapcell_chat_close_2").addEventListener("click", (event) => {
      toggleForm(event);
    });

    $$("send").addEventListener("click", (event) => {
      sendLiveVideo();
    });

    $$("send1").addEventListener("click", (event) => {
      sendMessage();
    });

    $$("snapcell_chat_close_button_").addEventListener("click", (event) => {
      hideForever();
    });

    $$("snapcell_mess_link_button").addEventListener("click", (event) => {
      changeView();
    });

    $$("snapcell_mess_link_button1").addEventListener("click", (event) => {
      changeView();
    });

    $$("snapcell_mess_link_button2").addEventListener("click", (event) => {
      changeView();
    });

    $$("snapcell_message_chat").addEventListener("submit", (event) => {
      writeMessage();
    });

    $$("snapcell_send_chat_button_sms").addEventListener("click", (event) => {
      sendMessageSms();
    });

    $$("snapcell_send_chat_button_video").addEventListener("click", (event) => {
      sendMessageVideo();
    });

    $$("snapcell_chat").addEventListener("mouseover", function (event) {
      this.classList.add("hover");
    });

    $$("snapcell_chat").addEventListener("mouseout", function (event) {
      this.classList.remove("hover");
    });

    $$("snapcell_mess_sales_button").addEventListener("click", (event) => {
      setDepartment("sales");
    });

    $$("snapcell_mess_service_button").addEventListener("click", (event) => {
      setDepartment("service");
    });

    addToggles();
  };

  const addToggles = () => {
    const elements = document.getElementsByClassName("snapcell_click");

    for (const element of Array.from(elements)) {
      element.addEventListener("click", snapcellToggle, false);
    }
  };

  const showLoading = () => {
    showClass(".snapcell_message_loading");
    const elements = getIframe().document.querySelectorAll(".snapcell_message_loading");
    elements[0].scrollIntoView();
  };

  const hideLoading = () => {
    hideClass(".snapcell_message_loading");
  };

  const buildRequestPhone = (question_id: string) => {
    const requestPhone = document.createElement("div");
    requestPhone.setAttribute("class", "request-telephone");
    requestPhone.setAttribute("id", "request-telephone");

    // Create an input element for telephone
    const phoneNumnberInput = document.createElement("input");
    phoneNumnberInput.setAttribute("type", "text");
    phoneNumnberInput.setAttribute("name", "telephone");
    phoneNumnberInput.setAttribute("id", "telephone");
    phoneNumnberInput.setAttribute("placeholder", "Phone number");
    phoneNumnberInput.setAttribute("class", "request-telephone-input");

    // Create an input element for question_id
    const questionIdInput = document.createElement("input");
    questionIdInput.setAttribute("type", "hidden");
    questionIdInput.setAttribute("name", "question_id");
    questionIdInput.setAttribute("id", "question_id");
    questionIdInput.setAttribute("value", question_id);

    const submitButton = document.createElement("button");
    submitButton.setAttribute("type", "submit");
    submitButton.innerHTML = "Submit";
    submitButton.setAttribute("class", "request-telephone-button");

    submitButton.addEventListener("click", (event) => {
      updateCustomerTelephone();
    });

    requestPhone.appendChild(phoneNumnberInput);
    requestPhone.appendChild(questionIdInput);
    requestPhone.appendChild(submitButton);
    return requestPhone;
  };

  const updateCustomerTelephone = () => {
    const question_id = $$<HTMLInputElement>("question_id").value;
    const params = { customer: { telephone: $$<HTMLInputElement>("telephone").value }, question_id };
    const json = JSON.stringify(params);
    const xhr = new XMLHttpRequest();
    const url = UPDATE_CUSTOMER_PHONE.replace(":id", CUSTOMER_ID);
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        $$("request-telephone").style.display = "none";
      } else {
        $$("snapcell_message_content_success_error").innerText = "Invalid number, please enter a valid number again";
        $$("snapcell_message_content_success_error").style.display = "block";
        $$("snapcell_message_content_success_error").focus();
      }
    };
    xhr.open("PATCH", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(json);
  };

  const main = () => {
    const hideForTime = localStorage["snapcell-hide-time"];

    if (hideForTime && Date.parse(hideForTime) > Date.now()) {
      return false;
    }

    fetchSettings((settings: Settings[]) => {
      ALL_SETTINGS = settings;
      CHAT = true;

      // We append the widget *after* getting the settings from our API.
      appendWidget();

      setTimeout(() => {
        if (settings.length === 1) {
          addFranchise(0, false);
        } else if (settings.length > 1) {
          addFranchise(0, true);
        }
      }, 120);
    });
  };

  main();
}
